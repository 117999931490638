<template>
  <div class="app-container">
    <CrudTable ref="table" entity="Dossier" :columns="columns" />
  </div>
</template>

<script>
import crudIndexMixin from '@/mixins/crud-index';
import CrudTable from '@/components/crud/CrudTable';
export default {
  components: {
    CrudTable
  },
  mixins: [crudIndexMixin],
  data() {
    return {
      columns: [
        { field: 'id', label: 'dossier.dossierID' },
        { field: 'requestDate', type: 'date', label: 'loan.requestDate', width: 100 },
        { field: 'requestStatus', label: 'loan.requestStatus', width: 180 },
        { field: 'personelnumber', label: 'dossier.personeelsNummer' },
        { field: 'initials', label: 'common.initialsShort', width: 60 },
        { field: 'middleName', label: 'common.middleNameShort', width: 60 },
        { field: 'lastName', label: 'common.lastName' },
        { field: 'genderLenerName', label: 'common.gender' },
        { field: 'dateOfBirth', type: 'date', width: 100 },
        { field: 'city' },
        { field: 'email', width: 250 }
      ]
    };
  }
};
</script>

<style scoped></style>
